import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Highlights.filter(function (x) {
    return _vm.gs.check_field(x.Text)
  }).length > 0
    ? _c(
        VContainer,
        {
          staticClass: "dxa-briefing-card mt-4",
          attrs: { id: "overall", fluid: "", tag: "section" },
        },
        [
          _c(
            "h4",
            { staticClass: "dxa-briefing-section-header h4 dark-color" },
            [_vm._v(" " + _vm._s(_vm.$t("highlights")) + " ")]
          ),
          _c(
            VRow,
            { staticClass: "mt-4" },
            _vm._l(
              _vm.Highlights.filter(function (x) {
                return _vm.gs.check_field(x.Text)
              }),
              function (item) {
                return _c(
                  VCol,
                  { key: item.Id, attrs: { cols: "12", md: "4" } },
                  [
                    !_vm.loading
                      ? _c(
                          "div",
                          { class: _vm.gs.isMobile() ? "pr-3" : "pr-12" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "text dark-color",
                                class: { "text-expanded": item.Expanded },
                                staticStyle: { "font-size": "1.1rem" },
                                attrs: { id: "Span-" + item.Id },
                              },
                              [
                                _c(VAvatar, {
                                  staticClass: "mr-2 mb-1",
                                  attrs: { color: "primary", size: "12" },
                                }),
                                _vm._v(_vm._s(_vm.gs.check_field(item.Text))),
                              ],
                              1
                            ),
                            item.ReadMoreVisible
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-4",
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            item.Expanded = !item.Expanded
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              item.Expanded
                                                ? "read_less"
                                                : "read_more"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }
            ),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }