import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-1" },
    [
      _c(
        "div",
        [
          _c(
            VRow,
            { attrs: { align: "end" } },
            [
              _c(
                VCol,
                {
                  staticClass: "pa-2",
                  attrs: { cols: "12", md: "9", order: "1" },
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "item-name h2 dark-color",
                      attrs: {
                        "data-test": "Pipeline:BriefingHeader:ItemName",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.Item.Name) + " ")]
                  ),
                ]
              ),
              _c(
                VCol,
                {
                  staticClass: "text-right pa-2",
                  staticStyle: { display: "flex", "justify-content": "end" },
                  attrs: {
                    cols: "12",
                    md: "3",
                    order: _vm.gs.isMobile() ? 2 : 1,
                  },
                },
                [
                  _c(
                    VMenu,
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VBtn,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "btn_index primary mr-0",
                                      staticStyle: {
                                        width: "221px",
                                        "box-shadow": "none !important",
                                        "border-radius": "15px !important",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        width: "160px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "18px" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("index")) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        width: "30px",
                                      },
                                    },
                                    [
                                      _c(VIcon, [
                                        _vm._v(
                                          "mdi-arrow-down-drop-circle-outline"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        VList,
                        { attrs: { tile: false, flat: "", nav: "" } },
                        _vm._l(_vm.GoToOptions, function (item, index) {
                          return _c(VListItemTitle, {
                            key: index,
                            staticClass: "titlelist px-3 py-3",
                            staticStyle: { "font-weight": "bold" },
                            attrs: { nav: "" },
                            domProps: {
                              textContent: _vm._s(_vm.$t(item.text)),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.go_to_click(item)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "pa-2",
                  attrs: { order: _vm.gs.isMobile() ? 1 : 2 },
                },
                [
                  _vm.Item.Hero != null
                    ? _c(VImg, {
                        staticClass: "mx-auto heroImage",
                        attrs: { src: _vm.gs.get_photo_path(_vm.Item.Hero) },
                        on: {
                          click: function ($event) {
                            return _vm.showVideo()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialog && _vm.SignedNDA
        ? _c(
            VDialog,
            {
              staticClass: "videoHero",
              attrs: { "max-width": "100%" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                VContainer,
                {
                  staticStyle: { width: "1500px !important" },
                  attrs: { id: "add-stock-modal", fluid: "", tag: "section" },
                },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "videoHero_modal",
                      staticStyle: { "padding-bottom": "15px" },
                    },
                    [
                      _c("div", { staticClass: "embed-container" }, [
                        _c("iframe", {
                          attrs: {
                            title: "Video Introdução",
                            src:
                              "https://player.vimeo.com/video/" +
                              _vm.Item.IntroVideo[_vm.$i18n.locale] +
                              "?portrait=0&byline=0&title=0",
                            frameborder: "0",
                            allowfullscreen: "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }