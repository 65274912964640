import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c(
        VBtn,
        {
          attrs: { icon: "", small: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("check")
            },
          },
        },
        [
          _vm.Checked
            ? _c("img", {
                attrs: {
                  src: require("@/assets/briefing_selector_checked.png"),
                  alt: "Icon Checked",
                },
              })
            : _c("img", {
                attrs: {
                  src: require("@/assets/briefing_selector.png"),
                  alt: "Icon Checked",
                },
              }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }