var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "embed-container" }, [
    _vm.Media.Plataform === _vm.videoPlatformEnum.VIMEO
      ? _c("iframe", {
          attrs: {
            title: "Video",
            src:
              "https://player.vimeo.com/video/" +
              _vm.Media.VideoId +
              "?portrait=0&byline=0&title=0",
            frameborder: "0",
            allowfullscreen: "",
          },
        })
      : _vm._e(),
    _vm.Media.Plataform === _vm.videoPlatformEnum.YOUTUBE
      ? _c("iframe", {
          attrs: {
            title: "Video",
            src:
              "https://www.youtube.com/embed/" +
              _vm.Media.VideoId +
              "?rel=0,showinfo=0,controls=0",
            frameborder: "0",
            allow:
              "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: "",
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }