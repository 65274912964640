import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Media.length > 0
    ? _c(
        VContainer,
        {
          staticClass: "dxa-briefing-card mt-4",
          style: _vm.gs.isMobile() ? "padding: 12px 16px !important" : "",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c(
            "h4",
            {
              staticClass: "dxa-briefing-section-header h4",
              style: _vm.gs.isMobile() ? "padding-left: 12px !important" : "",
            },
            [_vm._v(" " + _vm._s(_vm.Header) + " ")]
          ),
          _vm.Media.length > 0
            ? _c("div", { staticClass: "pt-5" }, [
                !_vm.gs.isMobile()
                  ? _c("div", { staticClass: "dxa-briefing-media-row" }, [
                      _c("table", { attrs: { "aria-label": "Tabela" } }, [
                        _c(
                          "tr",
                          _vm._l(_vm.Media, function (item) {
                            return _c(
                              "td",
                              {
                                key: item.Id,
                                staticClass: "mx-auto text-center",
                              },
                              [
                                _c("SingleMedia", {
                                  staticClass: "mr-6",
                                  attrs: { Media: item },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _c(
                      "div",
                      [
                        _c("SingleMedia", {
                          attrs: { Media: _vm.Media[_vm.index_selected] },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "mt-3",
                            staticStyle: { display: "flex" },
                          },
                          _vm._l(_vm.Media, function (n, i) {
                            return _c("DXASelector", {
                              key: i,
                              attrs: { Checked: _vm.index_selected == i },
                              on: {
                                check: function ($event) {
                                  _vm.index_selected = i
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }